import React, { useState, useEffect } from "react"
import { fromDurationToLabel } from "../util/TimeUtil"
import { toDisplaySlot } from "../util/OrderUtil"
import {
  Card,
  Header,
  Progress,
  Icon,
  Grid,
  Transition,
  Container,
} from "semantic-ui-react"
import Color from "../style/Color"

const progressStyle = {
  width: "100%",
};

const centerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
  fontSize: "1.5em",
};

const centerStyleHuge = {
  ...centerStyle,
  fontSize: "3.5em",
};

const countdownStyle = {
  fontSize: "3.5em",
  whiteSpace: "nowrap",
  color: "black",
};

const countdownStyleCentered = {
  ...countdownStyle,
  marginTop: "0.5em",
  fontSize: "2em",
};

const fullWidthStyle = { width: "100%" };

const OrderCountDown = ({ order, color, centered=false, onFinished=null, faded=false, pulse=false, nameLabel=null }) => {
  const { submittedOrderAt, startedCookingAt, finishedCookingAt, estimatedFinishedCookingAt, pickupSlot } = order;
  const [_, update] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      update(!_);
    }, 500);
    return () => clearTimeout(timer);
  });
  
  if(submittedOrderAt == null) {
    return "Order Not Submitted";
  }

  const startedMS = 1000 * startedCookingAt;
  const finishedSec = finishedCookingAt == null ? estimatedFinishedCookingAt : finishedCookingAt;
  const finishedMS = 1000 * finishedSec;
  const isEstimate = finishedCookingAt == null && estimatedFinishedCookingAt != null;

  const now = Date.now();
  const duration = finishedMS - startedMS;
  const timeLeft = finishedMS - now;
  const perc = 100 * (1 - timeLeft/duration);

  const label = fromDurationToLabel(timeLeft, isEstimate);
  const started = now >= startedMS;
  const timeUp = timeLeft <= 0;
  const completed = timeUp && !isEstimate;

  let status;
  if(perc >= 90 && !isEstimate) {
    status = "Baking";
  } else if(started && !isEstimate) {
    status = "Baking";
  } else {
    status = "In Line";
  }

  if(started && !faded) {
    color = Color.accent;
  }

  if(completed && onFinished) {
    onFinished();
  }

  const statusContent = (
    completed ?
      faded || !pulse ? 
        <Header as="h1" color={color} content={<>Ready in Slot {toDisplaySlot(pickupSlot)}</>} icon="checkmark" style={centered ? centerStyle : centerStyleHuge }/>
      :
        <Transition animation="pulse" duration={250} visible={!_}>
          <Container>
            <Header as="h1" color={color} content={<>Ready in Slot {toDisplaySlot(pickupSlot)}</>} icon="checkmark" style={centered ? centerStyle : centerStyleHuge }/>
          </Container>
        </Transition>
    :
      (
        timeUp || isEstimate ?
          <Card.Meta>
            <div style={centered ? null : {fontSize: "3.5em"}}>
              <b>{status}</b>
            </div>
          </Card.Meta>
        :
        <Card.Meta>
          <div style={centered ? null : {fontSize: centered ? "1.5em" : "2.5em" }}>
            <Progress active color={color} percent={perc} size={centered ? "small" : undefined} style={progressStyle}/>
            {status}
          </div>
        </Card.Meta>
      )
  );

  const timeContent = timeUp && !completed ?
    <div style={centered ? countdownStyleCentered : countdownStyle}>
      <Icon name="clock" color={color}/>{status}
    </div>
  :
  (
    <div style={centered ? countdownStyleCentered : countdownStyle}>
      <Icon name="clock" color={color}/>{label}
    </div>
  );

  if(centered && !isEstimate) {
    return (
      <Grid stackable columns={16} style={fullWidthStyle}>
        <Grid.Column width={16} textAlign={"center"}>
          <Grid.Row>
            {statusContent}
          </Grid.Row>
          <Grid.Row>
            <div className="spacer"/>
          </Grid.Row>
          <Grid.Row>
            {timeContent}
          </Grid.Row>
        </Grid.Column>
      </Grid>
    );
  }

  if(completed || isEstimate) {
    return (
      <Grid columns={16} style={fullWidthStyle}>
        { nameLabel != null && <Grid.Row width={16}>
          <Grid.Column width={16}>
            <div style={{ float: "left", marginTop: "2em"}}>
              {nameLabel}
            </div>
          </Grid.Column>
        </Grid.Row> }
        <Grid.Row>
          <Grid.Column width={16} textAlign={"center"}>
            {isEstimate ? timeContent : statusContent}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  return (
    <Grid columns={16} style={fullWidthStyle}>
      <Grid.Row width={16}>
        <Grid.Column width={16}>
          <div style={{ float: "left", marginTop: "2em"}}>
            {nameLabel}
          </div>
          <div style={{ float: "right", marginTop: "2em" }}>
            {timeContent}
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16} textAlign={"center"}>
          {statusContent}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default OrderCountDown;
