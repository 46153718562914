/* eslint eqeqeq: "off" */
import React, { useState, useEffect } from "react"
import { navigate, Link } from "gatsby"
import { Auth } from "aws-amplify";
import { AmplifySignOut } from "@aws-amplify/ui-react"
import ResponsiveContainer from "./ResponsiveContainer"
import connectStore from "../state/connectStore"
import OrderGate from "./OrderGate"
import OrderSummaryCard from "../ui/OrderSummaryCard"
import VisibilityTop from "../ui/VisibilityTop"
import QueriedImage from "../ui/QueriedImage"
import { getOrderToppings, getImage } from "../util/OrderUtil"
import Color from "../style/Color"

import {
  Container,
  Header,
  Icon,
  Segment,
  Modal,
  Divider,
  Button,
  Grid,
  Menu,
  Transition,
  Image,
} from "semantic-ui-react"

export const isLoggedIn = async() => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user != null;
  } catch(exception) {
    return false;
  }
};

const fullWidthHeaderStyle = { width: "100%", padding: 0, margin: 0 };
const fullWidthFooterStyle = { width: "100%" };

export const onPrev = () => navigate(-1);

const headerStyle = {
  width: "100%",
  border: "none",
  borderRadius: 0,
  boxShadow: "none",
  transition: "box-shadow 0.5s ease, padding 0.5s ease",
}

const fixedHeaderStyle = {
  width: "100%",
  boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
};

const fixedFooterStyle = {
  width: "100%",
  boxShadow: "0px -3px 5px rgba(0, 0, 0, 0.2)",
};

const centerAlignStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
};

export const LayoutHeader = ({ children, fixed=false, onPrev=null, order=null }) => {
  const header = (
    <Header as="h3">
      {children}
    </Header>
  );

  const content = (
    <Menu fixed={fixed ? "top" : null} style={fixed ? fixedHeaderStyle : headerStyle}>
      <Segment basic style={fullWidthHeaderStyle} textAlign="center" vertical>
        { fixed &&
        <Grid columns={16} style={fullWidthHeaderStyle}>
          <Grid.Column floated="left" width={3} verticalAlign="middle">
            { onPrev != null &&
              <Transition visible={fixed} animation="scale" duration={500} transitionOnMount>
                <Icon
                  onClick={onPrev}
                  name="angle left"
                  color={Color.accent}
                  size="big"
                />
              </Transition>
            }
          </Grid.Column>
          <Grid.Column width={10}>
            <div style={centerAlignStyle}>
              {header}
            </div>
          </Grid.Column>
          <Grid.Column floated="right" width={3}>
            { order != null &&
              <Transition visible={fixed} animation="scale" duration={500} transitionOnMount>
                <div>
                  <BarMenu order={order}/>
                </div>
              </Transition>
            }
          </Grid.Column>
        </Grid>
        }
        { !fixed && <>
            <Divider hidden/>
            <div style={centerAlignStyle}>
              {header}
            </div>
            <Divider hidden/>
          </>
        }
      </Segment>
    </Menu>
  );

  if(fixed) {
    return content;
  }

  return (
    <Transition visible animation="scale" duration={500} transitionOnMount>
      {content}
    </Transition>
  );
};

const LayoutFooter = ({ children, order, fixed=false, showBag=false }) => {
  return (
    <Menu fixed={fixed ? "bottom" : null} style={fixed ? fixedFooterStyle : headerStyle}>
      { showBag && <Bag order={order}/> }
      <Segment basic style={fullWidthFooterStyle} textAlign="center" vertical>
        <Transition visible animation="scale" duration={500} transitionOnMount>
          <Container>
            <div className="safari_only">
              {children}
            </div>
          </Container>
        </Transition>
      </Segment>
    </Menu>
  );
}

const BagSummary = ({ order }) => {
  if(order == null) {
    return null;
  }

  const images = [];
  if(order.cookie != null) {
    images.push(getImage(order.cookie));
  }
  getOrderToppings(order, "dry").forEach(t => images.push(getImage(t.name)));
  getOrderToppings(order, "wet").forEach(t => {
    images.push(getImage(t.name));
    if(t.extra) {
      images.push(getImage(t.name));
    }
  });

  return (<>
    {images.map((image, i) => (
      <Image
        key={i}
        circular
        size="tiny"
        className="shopping-bag-summary-icon"
        style={{
          transform: (i == 0 ? "translateY(-115%)" : "translateY(-" + ((i+1)*35 + 140) + "%)") + ((i%2==1) ? "translateX(-70%)" : ""),
        }}
      >
        {image}
      </Image>
    ))}
  </>);
};

const Bag = ({ order }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="shopping-bag">
      <BagSummary order={order}/>
      <Modal
        open={open}
        trigger={
          <Button
            compact
            icon={<Icon name="shopping cart" color={Color.accent}/>}
            circular
            content={1}
            size="large"
            onClick={() => setOpen(true)}
          />
        }
        content={
          <Container textAlign="center">
            <Divider hidden/>
            <Header as="h2" inverted><Icon name="shopping cart"/>Your Shopping Cart</Header>
            <Divider hidden/>
            <OrderSummaryCard order={order}/>
            <Divider hidden/>
            <Button color={Color.accent} onClick={() => setOpen(false)}>Hide</Button>
            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>
          </Container>
        }
        basic
        centered
        dimmer="blurring"
      />
    </div>
  );
}

const BarMenu = ({ order }) => {
  const [open, setOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    isLoggedIn().then(setLoggedIn);
  }, [setLoggedIn]);

  return (
    <Modal
      open={open}
      trigger={
        <Icon
          name="bars"
          color={Color.accent}
          size="big"
          onClick={() => setOpen(true)}
        />
      }
      content={
        <Container textAlign="center">
          {order != null && order.orderId != null && <>
            <Button onClick={() => navigate("/order-cookie")} icon="pencil" content="Edit Order"/>
            <Divider hidden className="half-divider"/>
          </>}
          <Button onClick={() => navigate("/order-qr-reader")} icon="qrcode" content="Scan an Order"/>
          <Divider hidden className="half-divider"/>
          <Button onClick={() => navigate("/order-status")} icon="clock" content="View Order Status"/>
          <Divider hidden className="half-divider"/>
          <Button icon="globe" href="https://www.robochef.ai" content="Visit robochef"/>
          <Divider hidden/>
          { loggedIn && <>
            <Header inverted>Advanced Features</Header>
            <Button onClick={() => navigate("/inventory")} icon="grid layout" content="Manage Inventory"/>
            <Divider hidden className="half-divider"/>
            <Button onClick={() => navigate("/order-qr-writer")} icon="qrcode" content="Generate Order"/>
            <Divider hidden className="half-divider"/>
            <Button onClick={() => navigate("/order-qr-sheet-writer")} icon="qrcode" content="Download QR Sheet"/>
            <Divider hidden className="half-divider"/>
            <Button onClick={() => navigate("/order-qr-zip-writer")} icon="qrcode" content="Download QR Zip"/>
            <Divider hidden className="half-divider"/>
            <AmplifySignOut onClick={() => window.location.reload()}/>
            <Divider hidden/>
          </>}
          <Button color={Color.accent} onClick={() => setOpen(false)}>Hide</Button>
          <Divider hidden/>
        </Container>
      }
      basic
      centered
      dimmer="blurring"
    />
  );
}

const LayoutMenuBar = ({ showBack, showMenu, onPrev, to, order, showOrder, menuButtonOnly }) => {
  if(menuButtonOnly) {
    return (
      <div className="menu-button-only">
        <BarMenu order={order}/>
      </div>
    );
  }
  
  return (
    <Menu>
      <Segment style={fullWidthHeaderStyle} textAlign="center" vertical inverted raised>
        <Grid columns={16} style={fullWidthHeaderStyle}>
          <Grid.Column floated="left" width={3}>
            { showBack && showMenu &&
              <Icon
                onClick={onPrev}
                name="angle left"
                color={Color.accent}
                size="big"
              />
            }
          </Grid.Column>
          <Grid.Column width={10}>
            <Header as="h2" inverted>
              <>
                <QueriedImage name="robochef-logo" imgStyle={{ objectFit: "contain" }} style={{ maxHeight: "35px" }} />
                { to != null && <Link to={to}/> }
              </>
            </Header>
          </Grid.Column>
          <Grid.Column floated="right" width={3}>
            {showMenu && <BarMenu order={order}/>}
          </Grid.Column>
        </Grid>
      </Segment>
    </Menu>
  );
}

const Layout = ({ children, location, order, validate=true, showOrder=true, showMenu=true, showBack=true, to=null, header=null, footer=null, showBag=false, hideMenu=false, menuButtonOnly=false }) => {
  // DEV - try out always showing back button
  showBack = true;

  const [fixedTop, setFixedTop] = useState(false);

  const content = (<>
    { header != null && <>
      { fixedTop &&
        <LayoutHeader fixed={fixedTop} onPrev={showBack ? onPrev : null} order={showOrder ? order : null}>
          {header}
        </LayoutHeader>
      }
      <VisibilityTop fixed={fixedTop} setFixed={setFixedTop}>
        <LayoutHeader onPrev={showBack ? onPrev : null} order={showOrder ? order : null}>
          {header}
        </LayoutHeader>
      </VisibilityTop>
    </>}
    {children}
    <div className="bottom-page-margin"/>
    { footer != null && <>
      <LayoutFooter fixed={true} showBag={showBag} order={order}>
        {footer}
      </LayoutFooter>
    </>}
  </>);

  return (
    <ResponsiveContainer>
      {!hideMenu && <LayoutMenuBar showMenu={showMenu} showBack={showBack} onPrev={onPrev} to={to} order={order} showOrder={showOrder} menuButtonOnly={menuButtonOnly} /> }
      <Container textAlign="center">
        { validate ?
          <OrderGate location={location}>{content}</OrderGate>
          :
          content
        }
      </Container>
    </ResponsiveContainer>
  );
};

export default connectStore(Layout);
