import React, { useCallback } from "react"
import { Visibility } from "semantic-ui-react"

const VisibilityTop = ({ children, fixed, setFixed, fireOnOffScreen=true, fireOnMount=true, updateOn="repaint" }) => {
  const stickTop = useCallback(() => setFixed(true), [setFixed]);
  const unStickTop = useCallback(() => setFixed(false), [setFixed]);

  return (
    <Visibility
      onTopPassed={stickTop}
      onTopVisible={unStickTop}
      onOffScreen={fireOnOffScreen ? stickTop : null}
      once={false}
      fireOnMount={fireOnMount}
      updateOn={updateOn}
    >
      {children}
    </Visibility>
  );
};

export default VisibilityTop;
