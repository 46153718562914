import React, { useState } from "react"
import { getData, getImage, toDisplaySlot } from "../util/OrderUtil"
import ToppingList from "../ui/ToppingList"
import OrderCountDown from "./OrderCountDown"
import {
  Card,
  Header,
  Icon,
  Label,
} from "semantic-ui-react"
import Color from "../style/Color"

const bannerStyleProp = {
  maxWidth: "300px",
  width: "100%", /* width of container */
  height: "100px", /* height of container */
  objectFit: "cover",
};

const compactBannerStyleProp = {
  maxWidth: "300px",
  width: "100%", /* width of container */
  height: "50px", /* height of container */
  objectFit: "cover",
};

const centerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
};

const OrderSummaryCard = ({ order, hideToppings=false, hideName=false, hideStatus=false, compact=false, faded=false }) => {
  const { cookie, dryToppings, wetToppings, customerName=null, submittedOrderAt, finishedCookingAt, pickupSlot=null } = order;
  const cookieData = getData(cookie);
  const toppings = [...dryToppings, ...wetToppings];

  const [poke, setPoke] = useState(0);
  
  if(cookieData == null) {
    return (
      <Card
      centered
      raised
      >
        <Card.Content>
          <Card.Header>
            You have not chosen a cookie.
          </Card.Header>
        </Card.Content>
      </Card>
    );
  }
  
  const now = Date.now();
  const hasName = customerName != null && customerName.length > 0;
  const hasStatus = submittedOrderAt != null;
  const finished = 1000*finishedCookingAt <= now;
  const color = finished && !faded ? Color.accent : Color.plain;

  const cookieImage = getImage(cookieData.name, {
    style: compact ? compactBannerStyleProp : bannerStyleProp,
  });

  return (
    <Card
      centered
      raised
    >
      {cookieImage}
      <Card.Content>
        <Card.Header>
          {cookieData.label}
        </Card.Header>
        {!compact &&
          <Card.Meta>
            Cookie
          </Card.Meta>
        }
      </Card.Content>
      { !hideToppings && toppings.length > 0 &&
        <Card.Content>
          <Card.Meta>
            <ToppingList toppings={toppings} compact={compact}/>
          </Card.Meta>
          {!compact &&
            <Card.Meta>
              Toppings
            </Card.Meta>
          }
        </Card.Content>
      }
      { hasName && !hideName &&
        <Card.Content>
          <Header as="h3" style={centerStyle}>
            <Icon name={finished ? "user" : "user outline"} color={color}/>{customerName}
            {finished && pickupSlot != null && <Label color={color} circular size="big">{toDisplaySlot(pickupSlot)}</Label>}
          </Header>
        </Card.Content>
      }
      { hasStatus && !hideStatus &&
        <Card.Content>
          <OrderCountDown order={order} color={color} centered faded={faded} onFinished={finished ? null : () => setPoke(poke+1)} pulse/>
        </Card.Content>
      }
    </Card>
  );
};

export default OrderSummaryCard;
