/* eslint eqeqeq: "off" */
import React from "react"
import { getData, getImage } from "../util/OrderUtil"
import {
  Icon,
  Image,
  List,
} from "semantic-ui-react"
import Color from "../style/Color"

const itemStyle = {
  display: "inline-block",
  paddingBottom: "0.5em",
};

const itemLabelStyle = {
  //display: "inline-block",
  paddingTop: "0.5em",
};

const imageUnCheckedStyle = {
  height: "80px",
  boxShadow: "rgba(0, 0, 0, 0.19) 0px 4px 20px, rgba(0, 0, 0, 0.23) 0px 4px 6px, rgba(0, 0, 0, 0.5) 0px 0px 10px 5px inset",
};

const imageCheckedStyle = {
  height: "80px",
  border: "0.35em solid " + Color.accentStyle,
  boxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px, rgba(0, 0, 0, 0.5) 0px 0px 10px 5px inset",
};

const compactImageUnCheckedStyle = {
  ...imageUnCheckedStyle,
  height: "35px",
};

const compactImageCheckedStyle = {
  ...imageCheckedStyle,
  height: "35px",
};

const ToppingItemContent = ({ topping, outline=false, hideLabel=false, onRemove=null, compact=false }) => {
  const { extra } = topping;
  const toppingData = getData(topping.name);
  const { name, label } = toppingData;

  const imageStyle = outline ?
    (compact ? compactImageCheckedStyle: imageCheckedStyle)
    :
    (compact ? compactImageUnCheckedStyle : imageUnCheckedStyle);

  const toppingImage = getImage(name, {
    style: imageStyle,
  });

  return (
    <>
      <Icon.Group>
        <Image
          circular
          size={compact ? "mini" : "tiny"}
          style={imageStyle}
        >
          {toppingImage}
        </Image>
      </Icon.Group>
      {!hideLabel &&
        <List.Content style={itemLabelStyle}>
          <List.Header>
            { extra && "Extra " }
            {label}
            { onRemove != null && <Icon name="close"/> }
          </List.Header>
        </List.Content>
      }
    </>
  );
};

const ToppingList = ({ toppings, hideLabel=false, onRemove=null, compact=false }) => (
  toppings.length == 0 ? null :
  <List horizontal>
  {toppings.map((topping, i) => (
    <List.Item key={topping.name} style={itemStyle} onClick={onRemove == null ? null : () => onRemove(topping)}>
      <ToppingItemContent topping={topping} hideLabel={hideLabel} onRemove={onRemove} compact={compact}/>
    </List.Item>
  ))}
  </List>
);

export default ToppingList;
