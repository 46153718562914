import React from "react"
import { navigate } from "gatsby"
import connectStore from "../state/connectStore"
import {
  Button,
  Message,
  Divider,
} from "semantic-ui-react"
import Color from "../style/Color"

const OrderGate = ({ children, order }) => {
  const invalid = order == null || order.orderId == null;
  if(invalid) {
    return (
      <>
        <Divider hidden/>
        <Message color={Color.accent} error>
          Oops! We can't detect your order.<br/>Please scan a QR code. If you scan a code for an existing order you'll be able to see the status again.<br/><br/>
          <Button compact color={Color.accent} onClick={() => navigate("/order-qr-reader")}>Scan an Order</Button>
        </Message>
      </>
    );
  }

  const submitted = order != null && order.submittedOrderAt != null;
  if(submitted) {
    return (
      <>
        <Divider hidden/>
        <Message color={Color.accent} info>
          You've already submitted the current order.<br/>What would you like to do?
          <Divider hidden/>
          <Button compact color={Color.accent} onClick={() => navigate("/order-status")}>View the Order</Button>
          <Button compact color={Color.accent} onClick={() => navigate("/order-qr-reader")}>Scan Another Order</Button>
        </Message>
      </>
    );
  }

  return children;
};

export default connectStore(OrderGate);
