/* eslint eqeqeq: "off" */
export const MS = 1;
export const SEC = 1000*MS;
export const MIN = 60*SEC;
export const HOUR = 60*MIN;
export const DAY = 24*HOUR;

const twoDigits = n => n < 10 ? "0" + n : "" + n;

export const toTimeParts = durationMS => {
  const ago = durationMS < 0;
  if(ago) {
    durationMS = -durationMS;
  }
  let remainder = durationMS;
  const days = Math.floor(remainder/DAY);
  remainder -= days * DAY;
  const hours = Math.floor(remainder/HOUR);
  remainder -= hours * HOUR;
  const mins = Math.floor(remainder/MIN);
  remainder -= mins * MIN;
  const secs = Math.floor(remainder/SEC);
  remainder -= secs * SEC;
  const millis = remainder;
  return { days, hours, mins, secs, millis, ago };
};

export const fromPartsToLabel = timeParts => {
  const { days=0, hours=0, mins=0, secs=0, ago=false } = timeParts;
  let label = twoDigits(mins) + ":" + twoDigits(secs);
  if(hours != 0 || days != 0) {
    label = twoDigits(hours) + ":" + label;
  }
  if(days != 0) {
    label = twoDigits(days) + ":" + label;
  }
  if(ago) {
    label = label + " ago";
  }
  return label;
};

export const fromDurationToEstimateLabel = durationMS => {
  const { days=0, hours=0, mins=0 } = toTimeParts(durationMS);
  if(days > 0) {
    if(days == 1) {
      return "≈ 1 day";
    }
    return "≈ " + days + " days";
  }
  if(hours > 0) {
    if(hours == 1) {
      return "≈ 1 hr";
    }
    return "≈ " + hours + " hr";
  }
  if(mins > 0) {
    if(mins == 1) {
      return "≈ 1 min";
    }
    return "≈ " + mins + " min";
  }
  return "Soon";
};

export const fromDurationToAgoLabel = durationMS => {
  const { days=0, hours=0, mins=0 } = toTimeParts(durationMS);
  if(days > 0) {
    if(days == 1) {
      return "1 day ago";
    }
    return days + " days ago";
  }
  if(hours > 0) {
    if(hours == 1) {
      return "1 hr ago";
    }
    return hours + " hr ago";
  }
  if(mins > 0) {
    if(mins == 1) {
      return "1 min ago";
    }
    return mins + " min ago";
  }
  return "just now";
};

export const fromDurationToLabel = (durationMS, isEstimate=false) => {
  if(durationMS < 0) {
    return fromDurationToAgoLabel(durationMS);
  }
  if(isEstimate) {
    return fromDurationToEstimateLabel(durationMS);
  }
  return fromPartsToLabel(toTimeParts(durationMS));
};