import React from "react"
import { createMedia } from "@artsy/fresnel"
import PropTypes from "prop-types"

const createdMedia = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

const MediaContextProvider = createdMedia.MediaContextProvider;
export const Media = createdMedia.Media;

const ResponsiveContainer = ({ children }) => (
  <MediaContextProvider>
    { children }
  </MediaContextProvider>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

export default ResponsiveContainer;
